import { InputCheckbox } from '@/components/form/inputs/InputCheckbox';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IFormInputCheckbox } from '@/typings';
import { PropType } from 'vue';

interface IProps {
  value?: boolean;
  input: IFormInputCheckbox;
  header: IDataTableHeader;
}

interface IEvents {
  onInput: (value: boolean) => void;
}

export const TdCheckbox = createComponent<IProps, IEvents>({
  name: 'TdCheckbox',
  props: {
    value: { type: Boolean, default: false },
    input: { type: Object as PropType<IFormInputCheckbox>, required: true },
    header: { type: Object as PropType<IDataTableHeader>, required: true },
  },
  setup(props, ctx) {
    function emitInput(value: boolean) {
      ctx.emit('input', value);
    }

    return () => (
      <div class="flex justify-center items-center" key={props.header.value}>
        <InputCheckbox
          input={props.input}
          value={props.value}
          onInput={emitInput}
        />
      </div>
    );
  },
});
