import { TdSwitch } from '@/components/datatable/columns/TdSwitch';
import { DataTable } from '@/components/datatable/DataTable';
import { PageSection } from '@/components/page/PageSection';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IFormInputSwitch,
  IRequireField,
  IUserProfissionalItem,
  IUserRecepcionistaProfissionalModel,
  IUsersQuery,
  IUsersQueryVariables,
  IDataTableHeader,
  UserTipo,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: IUserRecepcionistaProfissionalModel[];
  initialValue?: IUserRecepcionistaProfissionalModel[];
  hide?: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (model: IUserRecepcionistaProfissionalModel[]) => void;
}

export const UserProfissionais = createComponent<IJsxProps, IEvents>({
  name: 'UserProfissionais',
  props: {
    value: { type: Array, required: true },
    initialValue: { type: Array, default: () => [] },
    hide: { type: Boolean, default: false },
  },
  setup(props: IProps, ctx) {
    const headers = useHeaders();

    const { $data: $items, setData: setItems, $loading } = useUsersQuery(props);

    useWatchers({ ctx, $items, props, setItems });

    return () => (
      <PageSection divider hide={props.hide}>
        <DataTable
          title="Profissionais"
          headers={headers}
          items={$items.value}
          loading={$loading.value}
          scopedSlots={{}}
        />
      </PageSection>
    );
  },
});

function useHeaders(): IDataTableHeader<IUserProfissionalItem>[] {
  const schema: { atende: IFormInputSwitch } = {
    atende: {
      label: null,
      type: 'switch',
    },
  };

  return [
    {
      text: 'Atende',
      value: 'atende',
      slot: ({ header, item }) => [
        <TdSwitch
          header={header}
          input={schema.atende}
          value={item.atende}
          onInput={v => (item.atende = !!v)}
        />,
      ],
      align: 'center',
      sortable: false,
    },
    {
      text: 'Profissional',
      value: 'profissional',
      mapValue: v => v.profissional,
      align: 'start',
      valueAlign: 'start',
    },
  ];
}

function useUsersQuery(props: IProps) {
  return useQueryConfig<
    IUsersQuery,
    IUserProfissionalItem[],
    IUsersQueryVariables
  >({
    query: QueryGraphql.UsersQuery,
    variables: () => ({
      where: { tipo: UserTipo.PROFISSIONAL_SAUDE },
    }),
    mapData: result =>
      result?.users.nodes.map(v => ({
        profissionalId: v.id,
        profissional: v.nome,
        atende: toAtende(v.id, props),
      })) || [],
  });
}

function toAtende(userId: string, props: IProps) {
  return !!props.initialValue?.find(f => f.profissionalId === userId);
}

function useWatchers({
  props,
  ctx,
  $items,
  setItems,
}: {
  props: IProps;
  ctx: SetupContext;
  $items: Ref<IUserProfissionalItem[] | null>;
  setItems: (v: IUserProfissionalItem[] | null) => void;
}) {
  function emitInput(value: IUserRecepcionistaProfissionalModel[]) {
    ctx.emit('input', value);
  }

  watchRun(
    $items,
    items => {
      const value = (items || [])
        .filter(v => v.atende)
        .map(({ profissionalId }) => ({
          profissionalId,
        }));

      emitInput(value);
    },
    { deep: true },
  );

  watchRun(
    () => props.initialValue,
    () =>
      setItems(
        ($items.value || []).map(v => ({
          ...v,
          atende: toAtende(v.profissionalId, props),
        })),
      ),
    { deep: true },
  );

  return { emitInput };
}
