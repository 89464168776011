import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { MyPage } from '@/components/page/MyPage';
import { QueryGraphql } from '@/graphql/query';
import { IQueryConfig, useQueryConfig } from '@/lib/composables/useQueryConfig';
import { getUserEspecialidade, getUserTipo } from '@/lib/helpers/models/users';
import { UserService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IIndexDataTableActions,
  IIndexDataTableButton,
  IUserItem,
  IUsersQuery,
  IUsersQueryVariables,
  IDataTableHeader,
  UserTipo,
} from '@/typings';

export default createComponent({
  name: 'UsersPage',
  setup(props, ctx) {
    const { ProfissionaisTable } = useProfissionaisTable();
    const { RecepcionistasTable } = useRecepcionistasTable();

    return () => (
      <MyPage title="Usuários">
        <ProfissionaisTable />

        <RecepcionistasTable />
      </MyPage>
    );
  },
});

const getQueryConfig = (
  recepcionista: boolean,
): IQueryConfig<IUsersQuery, IUserItem[], IUsersQueryVariables> => ({
  query: QueryGraphql.UsersQuery,
  variables: () => ({
    where: {
      tipo: recepcionista
        ? UserTipo.RECEPCIONISTA
        : UserTipo.PROFISSIONAL_SAUDE,
    },
  }),
  mapData: result =>
    result?.users.nodes.map(v => {
      return {
        id: v.id,
        nome: v.nome,
        email: v.email,
        tipo: getUserTipo(v),
        especialidade: recepcionista ? null : getUserEspecialidade(v),
      };
    }) || [],
});

const actions: IIndexDataTableActions = {
  editRoute: Routes.app.configAccount.usuarios.edit,
  removeMany: UserService.deleteMany,
};

function useProfissionaisTable() {
  const btn: IIndexDataTableButton = {
    text: 'Novo Profissional',
    to: Routes.app.configAccount.usuarios.newProfissional,
  };

  const headers: IDataTableHeader<IUserItem>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
    {
      text: 'Email',
      value: 'email',
      mapValue: v => v.email,
    },
    {
      text: 'Especialidade',
      value: 'especialidade',
      mapValue: v => v.especialidade,
    },
  ];

  const { $data, $loading } = useQueryConfig(getQueryConfig(false));

  const ProfissionaisTable = () => (
    <IndexDataTable
      title="Profissionais de saúde"
      btn={btn}
      headers={headers}
      items={$data.value}
      loading={$loading.value}
      actions={actions}
    />
  );

  return { ProfissionaisTable };
}

function useRecepcionistasTable() {
  const btn: IIndexDataTableButton = {
    text: 'Nova Recepcionista',
    to: Routes.app.configAccount.usuarios.newRecepcionista,
  };

  const headers: IDataTableHeader<IUserItem>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
    {
      text: 'Email',
      value: 'email',
      mapValue: v => v.email,
    },
  ];

  const { $data, $loading } = useQueryConfig(getQueryConfig(true));

  const RecepcionistasTable = () => (
    <IndexDataTable
      divider
      title="Recepcionistas"
      btn={btn}
      headers={headers}
      items={$data.value}
      loading={$loading.value}
      actions={actions}
    />
  );

  return { RecepcionistasTable };
}
