import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { AccountService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IContaPermissoesEnvioModel } from '@/typings';
import { PermissoesEnvioForm } from '../components/forms/PermissoesEnvioForm';

export default createComponent({
  name: 'PermissoesEnvioPage',
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IContaPermissoesEnvioModel>({
      ctx,
      submitCallback(model) {
        return AccountService.updatePermissoesEnvio(model);
      },
    });

    return () => (
      <MyPage title="Permissões de envio">
        <PermissoesEnvioForm page={page} onSubmit={handleSubmit} />
      </MyPage>
    );
  },
});
