import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IAssinaturaModel, IFormSchema } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IAssinaturaModel> {}

export const AssinaturaForm = createComponent<IProps, IEvents>({
  name: 'AssinaturaForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    return () => (
      <MyForm form={$form.value} onSubmit={emitSubmit}>
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});
function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IAssinaturaModel, IFormSchema<IAssinaturaModel>>({
    page: props.page,
    initialValue: {
      nomeImpressoCartao: null,
      numeroCartao: null,
      dataVencimentoCartao: null,
      cvvCartao: null,
      cpfOrCnpj: null,
    },
    mapSchema: () => ({
      nomeImpressoCartao: {
        label: 'Nome impresso no cartão',
        type: 'text',
        validations: {
          required: true,
        },
      },
      numeroCartao: {
        label: 'Número do cartão',
        type: 'text',
        placeholder: '0000 0000 0000 0000',
        mask: 'cartaoCredito',
        validations: {
          required: true,
        },
      },
      dataVencimentoCartao: {
        label: 'Data de vencimento',
        type: 'text',
        placeholder: 'MM/AAAA',
        mask: 'mesAno',
        validations: {
          required: true,
        },
        layout: {
          sm: 6,
        },
      },
      cvvCartao: {
        label: 'CVV',
        type: 'text',
        mask: 'cvv',
        validations: {
          required: true,
        },
        layout: {
          sm: 6,
        },
      },
      cpfOrCnpj: {
        label: 'CPF/CNPJ',
        type: 'text',
        mask: 'cpfOrCnpj',
        validations: {
          required: true,
        },
      },
    }),
    ctx,
  });
}
