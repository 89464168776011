import { TdCheckbox } from '@/components/datatable/columns/TdCheckbox';
import { TdSwitch } from '@/components/datatable/columns/TdSwitch';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { watchRun } from '@/lib/vue';
import { getClinica, getUser } from '@/store/utils/auth';
import {
  IClinicasQuery,
  IClinicasQueryVariables,
  IDataTableHeader,
  IFormInputCheckbox,
  IFormInputSwitch,
  IUserClinicaItem,
  IUserClinicaModel,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';
import { IUserClinicasProps } from '../UserClinicas';

export const UserClinicasHelpers = {
  useHeaders,

  useClinicasQuery(props: IUserClinicasProps) {
    return useQueryConfig<
      IClinicasQuery,
      IUserClinicaItem[],
      IClinicasQueryVariables
    >({
      query: QueryGraphql.ClinicasQuery,
      variables: () => ({}),
      mapData: result =>
        result?.clinicas.nodes.map(v => ({
          clinicaId: v.id,
          clinica: v.nome,
          adminClinica: toAdminClinica({ clinicaId: v.id, props }),
          atende: toAtende({ clinicaId: v.id, props }),
        })) || [],
    });
  },

  useWatchers({
    ctx,
    $items,
    props,
    setItems,
  }: {
    ctx: SetupContext;
    props: IUserClinicasProps;
    $items: Ref<IUserClinicaItem[] | null>;
    setItems: (v: IUserClinicaItem[] | null) => void;
  }) {
    function emitInput(value: IUserClinicaModel[]) {
      ctx.emit('input', value);
    }

    watchRun(
      $items,
      items => {
        const value = (items || [])
          .filter(v => v.atende)
          .map(({ clinicaId, adminClinica }) => ({
            clinicaId,
            adminClinica,
          }));

        emitInput(value);
      },
      { deep: true },
    );

    watchRun(
      () => props.initialValue,
      () =>
        setItems(
          ($items.value || []).map(v => ({
            ...v,
            adminClinica: toAdminClinica({ clinicaId: v.clinicaId, props }),
            atende: toAtende({ clinicaId: v.clinicaId, props }),
          })),
        ),
      { deep: true },
    );

    return { emitInput };
  },
};

function useHeaders({
  props,
  $items,
}: {
  $items: Ref<IUserClinicaItem[] | null>;
  props: IUserClinicasProps;
}): IDataTableHeader<IUserClinicaItem>[] {
  return [
    {
      text: 'Atende',
      value: 'atende',
      slot: ({ header, item }) => (
        <div
          aria-label={atendeTooltip({ props, item, $items })}
          data-balloon-pos="right"
          class="flex justify-center items-center"
        >
          <TdSwitch
            header={header}
            input={getSchema({ item, props, $items }).atende}
            value={item.atende}
            onInput={v => (item.atende = !!v)}
          />
        </div>
      ),
      align: 'center',
      sortable: false,
      width: 80,
    },
    {
      text: 'Clínica',
      value: 'clinica',
      mapValue: v => v.clinica,
      align: 'start',
      valueAlign: 'start',
    },
    {
      text: 'Administrador da clínica',
      value: 'adminClinica',
      slot: ({ header, item }) => (
        <TdCheckbox
          header={header}
          input={getSchema({ item, props, $items }).adminClinica}
          value={item.adminClinica}
          onInput={v => (item.adminClinica = v)}
        />
      ),
      align: 'center',
      sortable: false,
    },
  ];
}

function getSchema({
  item,
  $items,
  props,
}: {
  item: IUserClinicaItem;
  $items: Ref<IUserClinicaItem[] | null>;
  props: IUserClinicasProps;
}): { atende: IFormInputSwitch; adminClinica: IFormInputCheckbox } {
  return {
    atende: {
      label: null,
      type: 'switch',
      disabled:
        (isEdit(props) && onlyClinica($items)) ||
        isCurrentClinica({ props, item }),
    },
    adminClinica: {
      label: null,
      type: 'checkbox',
      disabled: !item.atende,
    },
  };
}

function atendeTooltip({
  props,
  $items,
  item,
}: {
  props: IUserClinicasProps;
  $items: Ref<IUserClinicaItem[] | null>;
  item: IUserClinicaItem;
}) {
  if (isEdit(props) && onlyClinica($items)) {
    return 'Precisa ser membro de pelo menos uma clínica';
  } else if (isCurrentClinica({ props, item })) {
    return 'Clínica atual, selecione outra clínica';
  }

  return null;
}

function isCurrentClinica({
  props,
  item,
}: {
  props: IUserClinicasProps;
  item: IUserClinicaItem;
}) {
  const isMyUser = props.form.page.id === getUser().id;

  const currentClinica = item.clinicaId === getClinica().id;

  return isMyUser && currentClinica;
}

const isEdit = (props: IUserClinicasProps) => !!props.form.page.id;

const onlyClinica = ($items: Ref<IUserClinicaItem[] | null>) =>
  ($items.value || []).length === 1;

function toAtende({
  clinicaId,
  props,
}: {
  clinicaId: string;
  props: IUserClinicasProps;
}) {
  return !!(props.initialValue || []).find(f => f.clinicaId === clinicaId);
}

function toAdminClinica({
  clinicaId,
  props,
}: {
  clinicaId: string;
  props: IUserClinicasProps;
}) {
  return !!(props.initialValue || []).find(f => f.clinicaId === clinicaId)
    ?.adminClinica;
}
