import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { AccountService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IContaPermissoesEnvioModel, IFormSchema } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IContaPermissoesEnvioModel> {}

export const PermissoesEnvioForm = createComponent<IProps, IEvents>({
  name: 'PermissoesEnvioForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    return () => (
      <MyForm noCancel form={$form.value} onSubmit={emitSubmit}>
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
            class="mb-4"
          />
        </PageSection>
      </MyForm>
    );
  },
});
function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IContaPermissoesEnvioModel,
    IFormSchema<IContaPermissoesEnvioModel>
  >({
    page: props.page,
    initialValue: {
      permiteSms: true,
      permiteEmail: true,
    },
    mapSchema: () => ({
      permiteSms: {
        label: 'Permitir envio de mensagens SMS em toda a conta',
        type: 'switch',
      },
      permiteEmail: {
        label: 'Permitir envio de mensagens de e-mail em toda a conta',
        type: 'switch',
      },
    }),
    ctx,
    noId: true,
    async loadEditCallback({ setFormModel }) {
      const editValue = await AccountService.getPermissoesEnvio();

      if (editValue) {
        setFormModel({
          permiteSms: editValue.permiteSms,
          permiteEmail: editValue.permiteEmail,
        });
      }
    },
  });
}
