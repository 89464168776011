import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'SmsEnviadosPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Data',
        value: 'data',
      },
      {
        text: 'Paciente',
        value: 'paciente',
      },
      {
        text: 'Status',
        // ex: Entregue ao usuário
        value: 'status',
      },
    ];

    return () => (
      <MyPage title="SMS Enviados">
        <DataTable
          headers={headers}
          items={items}
          // loading={loading}
        />
      </MyPage>
    );
  },
});
