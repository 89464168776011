import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';

export default createComponent({
  name: 'ConfiguracaoContaSidebar',
  setup(props, ctx) {
    const menus: IMenu[] = [
      {
        text: 'Configurações da conta',
        header: true,
      },
      {
        text: 'Assinatura',
        icon: MyIcons.assinatura,
        to: Routes.app.configAccount.assinatura,
      },
      {
        text: 'Histórico de cobranças',
        icon: MyIcons.historico,
        to: Routes.app.configAccount.historicoCobrancas,
      },
      {
        text: 'Clínicas',
        icon: MyIcons.clinica,
        to: Routes.app.configAccount.clinicas.index,
      },
      {
        text: 'Usuários',
        icon: MyIcons.users,
        to: Routes.app.configAccount.usuarios.index,
      },
      {
        text: 'Comunicação',
        subheader: true,
      },
      {
        text: 'Permissões de envio',
        icon: MyIcons.permissoesEnvio,
        to: Routes.app.configAccount.permissoesEnvio,
      },
      {
        text: 'SMS enviados',
        icon: MyIcons.smsEnviados,
        to: Routes.app.configAccount.smsEnviados,
      },
    ];

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={menus} />
      </ContextSidebar>
    );
  },
});
