import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'HistoricoCobrancasPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Data',
        value: 'data',
      },
      {
        text: 'Descrição',
        value: 'descricao',
      },
      {
        text: 'Valor',
        value: 'valor',
      },
      {
        text: 'Saldo',
        value: 'saldo',
      },
    ];

    return () => (
      <MyPage title="Histórico de Cobranças">
        <DataTable
          headers={headers}
          items={items}
          // loading={loading}
        />
      </MyPage>
    );
  },
});
