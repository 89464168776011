import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import { validationSummary } from '@/lib/form';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IForm,
  IFormSchema,
  IFormValidationErrors,
  IRequireField,
  IUserRedefinirSenhaModel,
} from '@/typings';
import { computed, ComputedRef, Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: IUserRedefinirSenhaModel;
  form: IForm<any>;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (model: IUserRedefinirSenhaModel) => void;
  onValidation: (validation: IFormValidationErrors[]) => void;
}

export const UserRedefinirSenhaFields = createComponent<IJsxProps, IEvents>({
  name: 'UserRedefinirSenhaFields',
  props: {
    form: { type: Object, required: true },
    value: { type: Object, required: true },
  },
  setup(props: IProps, ctx) {
    const [$show, setShow] = useValue(false);

    const { $schema, $validation } = useComputeds({ props, $show });

    const { handleRedefinirSenha, emitInput } = useEvents({
      ctx,
      $validation,
      setShow,
    });

    return () => (
      <div class="flex flex-col">
        <ShowBtn
          show={$show.value}
          handleRedefinirSenha={handleRedefinirSenha}
        />

        <PasswordForm
          show={$show.value}
          form={props.form}
          schema={$schema.value}
          value={props.value}
          emitInput={emitInput}
        />
      </div>
    );
  },
});

function useComputeds({
  props,
  $show,
}: {
  props: IProps;
  $show: Ref<boolean>;
}) {
  const $schema = computed<IFormSchema<IUserRedefinirSenhaModel>>(() => ({
    newPassword: {
      label: 'Senha',
      type: 'password',
      validations: {
        required: $show.value,
        minLength: 6,
      },
    },
    confirmNewPassword: {
      label: 'Confirmar senha',
      type: 'password',
      validations: {
        required: $show.value,
        confirmPassword: {
          field: 'confirmNewPassword',
          fieldToConfirm: 'newPassword',
        },
      },
    },
  }));

  const $validation = computed<IFormValidationErrors[]>(() => {
    if (!$show.value) return [];

    return validationSummary({
      schema: $schema.value,
      model: props.value,
      form: { submitted: true },
      customErrors: [],
    });
  });

  return { $schema, $validation };
}

function useEvents({
  ctx,
  $validation,
  setShow,
}: {
  ctx: SetupContext;
  $validation: ComputedRef<IFormValidationErrors[]>;
  setShow: (v: boolean) => void;
}) {
  function handleRedefinirSenha() {
    setShow(true);
  }

  function emitInput(value: IUserRedefinirSenhaModel) {
    ctx.emit('input', value);
  }

  function emitValidation() {
    ctx.emit('validation', $validation.value);
  }

  watchRun($validation, emitValidation);

  return {
    handleRedefinirSenha,
    emitInput,
    emitValidation,
  };
}

const ShowBtn = createComponent<{
  show: boolean;
  handleRedefinirSenha: () => void;
}>({
  name: 'UserRedefinirSenhaFieldsShowBtn',
  props: {
    show: { type: Boolean, required: true },
    handleRedefinirSenha: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () =>
      !props.show && (
        <div class="flex justify-center">
          <v-btn
            color="primary"
            outlined
            onClick={props.handleRedefinirSenha}
            class="mb-6"
          >
            <v-icon left>{MyIcons.passwordReset}</v-icon>
            Redefinir senha
          </v-btn>
        </div>
      );
  },
});

interface IPasswordFormProps {
  show: boolean;
  form: IForm<any>;
  schema: IFormSchema<IUserRedefinirSenhaModel>;
  value: IUserRedefinirSenhaModel;
  emitInput: (model: IUserRedefinirSenhaModel) => void;
}

const PasswordForm = createComponent<IPasswordFormProps>({
  name: 'UserRedefinirSenhaFieldsPasswordForm',
  props: {
    show: { type: Boolean, required: true },
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    value: { type: Object, required: true },
    emitInput: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection divider hide={!props.show} title="Redefinir senha">
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema}
          value={props.value}
          onInput={props.emitInput}
        />
      </PageSection>
    );
  },
});
