import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { UserService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IUserFormModel } from '@/typings';
import { UserForm } from '../../components/user/UserForm';

export default createComponent({
  name: 'MeuPerfilPage',
  setup(props, ctx) {
    const { $user } = useAuthInfo();

    const { page, handleSubmit } = useFormPage({
      ctx,
      id: $user.value?.id,
      submitCallback(model: IUserFormModel) {
        return UserService.updateMe(model);
      },
    });

    return () => (
      <MyPage title="Meu perfil" form>
        <UserForm perfil page={page} onSubmit={handleSubmit} />
      </MyPage>
    );
  },
});
