import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { MyPage } from '@/components/page/MyPage';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { ClinicaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IClinicaItem,
  IClinicasQuery,
  IClinicasQueryVariables,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IDataTableHeader,
} from '@/typings';

export default createComponent({
  name: 'ClinicasPage',
  setup(props, ctx) {
    const btn: IIndexDataTableButton = {
      text: 'Nova Clínica',
      to: Routes.app.configAccount.clinicas.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.configAccount.clinicas.edit,
      removeMany: ClinicaService.deleteMany,
    };

    const headers: IDataTableHeader<IClinicaItem>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
      {
        text: 'Bairro',
        value: 'bairro',
        mapValue: v => v.enderecoBairro,
      },
      {
        text: 'Cidade',
        value: 'cidade',
        mapValue: v => v.enderecoCidade,
      },
      {
        text: 'UF',
        value: 'uf',
        mapValue: v => v.enderecoUf,
      },
    ];

    const { $data, $loading } = useClinicasQuery();

    return () => (
      <MyPage title="Clínicas">
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </MyPage>
    );
  },
});

function useClinicasQuery() {
  return useQueryConfig<
    IClinicasQuery,
    IClinicaItem[],
    IClinicasQueryVariables
  >({
    query: QueryGraphql.ClinicasQuery,
    variables: () => ({}),
    mapData: result =>
      result?.clinicas.nodes.map(v => ({
        id: v.id,
        nome: v.nome,
        enderecoBairro: v.enderecoBairro,
        enderecoCidade: v.enderecoCidade,
        enderecoUf: v.enderecoUf,
      })) || [],
  });
}
