import { DataTable } from '@/components/datatable/DataTable';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IForm, IUserClinicaModel, IUserFormModel } from '@/typings';
import { UserClinicasHelpers } from './helpers/userClinicas.helpers';

interface IProps {
  // model
  value: IUserClinicaModel[];
  initialValue?: IUserClinicaModel[];
  form: IForm<IUserFormModel>;
}

export type IUserClinicasProps = IProps;

interface IEvents {
  onInput: (model: IUserClinicaModel[]) => void;
}

export const UserClinicas = createComponent<IProps, IEvents>({
  name: 'UserClinicas',
  props: {
    value: { type: Array, required: true },
    initialValue: { type: Array, default: () => [] },
    form: { type: Object, required: true },
  },
  setup(props: IUserClinicasProps, ctx) {
    const { useHeaders, useClinicasQuery, useWatchers } = UserClinicasHelpers;

    const {
      $data: $items,
      setData: setItems,
      $loading,
    } = useClinicasQuery(props);

    const headers = useHeaders({ props, $items });

    useWatchers({ ctx, $items, setItems, props });

    return () => (
      <PageSection divider>
        <DataTable
          title="Clínicas"
          headers={headers}
          items={$items.value}
          loading={$loading.value}
        />
      </PageSection>
    );
  },
});
