import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { UserService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IUserFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { UserForm } from '../../components/user/UserForm';

export default createComponent({
  name: 'UserFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage({
      ctx,
      async submitCallback(model: IUserFormModel) {
        if (page.id) {
          return UserService.update(page.id, model);
        } else {
          return UserService.create(model);
        }
      },
    });

    const { $nome, setNome } = useNome({ ctx });

    const $title = computed(() => {
      if ($nome.value) {
        return `Usuário: ${$nome.value}`;
      }

      return isEdit ? 'Editar Usuário' : 'Novo Usuário';
    });

    async function handleDelete(id: string) {
      return UserService.delete(id);
    }

    return () => (
      <MyPage title={$title.value} form>
        <UserForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </MyPage>
    );
  },
});
