import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { IPerfilImagem, PerfilImagem } from '@/components/perfil/PerfilImagem';
import { FormHeader } from '@/components/typography/FormHeader';
import { createComponent } from '@/lib/vue';
import {
  IForm,
  IFormValidationErrors,
  IUserClinicaModel,
  IUserFormModel,
  IUserFormSchema,
  IUserRecepcionistaProfissionalModel,
} from '@/typings';
import { UserProfissionais } from '../UserProfissionais';
import { UserRedefinirSenhaFields } from '../UserRedefinirSenhaFields';
import { UserClinicas } from './UserClinicas';

export const UsuarioFields = createComponent<{
  form: IForm<IUserFormModel>;
  schema: IUserFormSchema;
}>({
  name: 'UsuarioFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    async function handleImagemInput({ image, upload }: IPerfilImagem) {
      props.form.model.imagem.url = image;

      props.form.page.submitting = true;
      props.form.model.imagem.imagemId = await upload;
      props.form.page.submitting = false;
    }

    function handleImagemRemove() {
      props.form.model.imagem.imagemId = null;
      props.form.model.imagem.url = null;
    }

    return () => (
      <PageSection titleMinWidth={250}>
        <div class="flex flex-col" slot="title">
          <FormHeader title="Usuário" />

          <PerfilImagem
            value={props.form.model.imagem.url}
            name={props.form.model.usuario.nome}
            onInput={handleImagemInput}
            onRemove={handleImagemRemove}
          />
        </div>

        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.usuario}
          v-model={props.form.model.usuario}
        />
      </PageSection>
    );
  },
});

export const RedefinirSenhaFields = createComponent<{
  form: IForm<IUserFormModel>;
  isEdit: boolean;
  setRedefinirSenhaErrors: (v: IFormValidationErrors[]) => void;
}>({
  name: 'RedefinirSenhaFields',
  props: {
    isEdit: { type: Boolean, required: true },
    form: { type: Object, required: true },
    setRedefinirSenhaErrors: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () =>
      props.isEdit && (
        <UserRedefinirSenhaFields
          form={props.form}
          v-model={props.form.model.redefinirSenha}
          onValidation={props.setRedefinirSenhaErrors}
        />
      );
  },
});

export const ProfissionalSaudeFields = createComponent<{
  isProfissionalSaude: boolean;
  form: IForm<IUserFormModel>;
  schema: IUserFormSchema;
}>({
  name: 'ProfissionalSaudeFields',
  props: {
    isProfissionalSaude: { type: Boolean, required: true },
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection
        title="Profissional de saúde"
        divider
        hide={!props.isProfissionalSaude}
      >
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.profissionalSaude}
          v-model={props.form.model.profissionalSaude}
        />
      </PageSection>
    );
  },
});

export const ProfissionaisFields = createComponent<{
  perfil: boolean;
  isRecepcionista: boolean;
  form: IForm<IUserFormModel>;
  initialProfissionais: IUserRecepcionistaProfissionalModel[];
}>({
  name: 'ProfissionaisFields',
  props: {
    perfil: { type: Boolean, required: true },
    isRecepcionista: { type: Boolean, required: true },
    form: { type: Object, required: true },
    initialProfissionais: { type: Array, required: true },
  },
  setup(props, ctx) {
    return () =>
      !props.perfil && (
        <UserProfissionais
          hide={!props.isRecepcionista}
          v-model={props.form.model.profissionais}
          initialValue={props.initialProfissionais}
        />
      );
  },
});

export const ClinicasFields = createComponent<{
  perfil: boolean;
  form: IForm<IUserFormModel>;
  initialClinicas: IUserClinicaModel[];
}>({
  name: 'ClinicasFields',
  props: {
    perfil: { type: Boolean, required: true },
    form: { type: Object, required: true },
    initialClinicas: { type: Array, required: true },
  },
  setup(props, ctx) {
    return () =>
      !props.perfil && (
        <UserClinicas
          form={props.form}
          v-model={props.form.model.clinicas}
          initialValue={props.initialClinicas}
        />
      );
  },
});
