import { MyForm } from '@/components/form/MyForm';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRoute } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { UserService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IForm,
  IFormValidationErrors,
  IUserClinicaModel,
  IUserFormModel,
  IUserFormSchema,
  IUserRecepcionistaProfissionalModel,
  UserTipo,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import {
  ClinicasFields,
  ProfissionaisFields,
  ProfissionalSaudeFields,
  RedefinirSenhaFields,
  UsuarioFields,
} from './userForm/UserFormFields';

interface IProps extends IFormProps {
  perfil?: boolean;
}

interface IEvents extends IFormEvents<IUserFormModel>, INomeEvents {}

export const UserForm = createComponent<IProps, IEvents>({
  name: 'UserForm',
  props: {
    page: { type: Object, required: true },
    perfil: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const indexRoute = Routes.app.configAccount.usuarios.index;

    const {
      $form,
      emitSubmit,
      emitDelete,
      $schema,
      isEdit,
      setRedefinirSenhaErrors,
      $initialClinicas,
      $initialProfissionais,
    } = useForm(props, ctx);

    const { $noDelete, $isProfissionalSaude, $isRecepcionista } = useData({
      props,
      $form,
    });

    useParams({ ctx, $form });

    useNome({
      ctx,
      watchNome: () => $form.value.model.usuario.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        noCancel={props.perfil}
        noDelete={$noDelete.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <UsuarioFields form={$form.value} schema={$schema.value} />

        <RedefinirSenhaFields
          form={$form.value}
          isEdit={isEdit}
          setRedefinirSenhaErrors={setRedefinirSenhaErrors}
        />

        <ProfissionalSaudeFields
          form={$form.value}
          schema={$schema.value}
          isProfissionalSaude={$isProfissionalSaude.value}
        />

        <ProfissionaisFields
          form={$form.value}
          perfil={!!props.perfil}
          initialProfissionais={$initialProfissionais.value}
          isRecepcionista={$isRecepcionista.value}
        />

        <ClinicasFields
          form={$form.value}
          perfil={!!props.perfil}
          initialClinicas={$initialClinicas.value}
        />
      </MyForm>
    );
  },
});

function useData({
  props,
  $form,
}: {
  props: IProps;
  $form: Ref<IForm<IUserFormModel>>;
}) {
  const { $user, $userIsAdmin } = useAuthInfo();

  const $noDelete = computed(
    () =>
      props.perfil || props.page.id === $user.value?.id || !$userIsAdmin.value,
  );

  const $isProfissionalSaude = computed(
    () => $form.value.model.usuario.tipo === UserTipo.PROFISSIONAL_SAUDE,
  );

  const $isRecepcionista = computed(
    () => $form.value.model.usuario.tipo === UserTipo.RECEPCIONISTA,
  );

  return { $noDelete, $isProfissionalSaude, $isRecepcionista };
}

function useParams({
  ctx,
  $form,
}: {
  ctx: SetupContext;
  $form: Ref<IForm<IUserFormModel>>;
}) {
  const queryProfissional = useRoute().query.tipo === 'profissional';

  const queryRecepcionista = useRoute().query.tipo === 'recepcionista';

  if (queryProfissional) {
    $form.value.model.usuario.tipo = UserTipo.PROFISSIONAL_SAUDE;
  } else if (queryRecepcionista) {
    $form.value.model.usuario.tipo = UserTipo.RECEPCIONISTA;
  }
}

function useForm(props: IProps, ctx: SetupContext) {
  const [$initialProfissionais, setInitialProfissionais] = useValue<
    IUserRecepcionistaProfissionalModel[]
  >([]);

  const [$initialClinicas, setInitialClinicas] = useValue<IUserClinicaModel[]>(
    [],
  );

  const [$redefinirSenhaErrors, setRedefinirSenhaErrors] = useValue<
    IFormValidationErrors[]
  >([]);

  const { $form, $schema, emitSubmit, emitDelete, isEdit } = useFormConfig<
    IUserFormModel,
    IUserFormSchema
  >({
    page: props.page,
    initialValue: {
      usuario: {
        nome: null,
        email: null,
        tipo: null,
        adminAccount: false,
        sexo: null,
      },
      redefinirSenha: {
        newPassword: null,
        confirmNewPassword: null,
      },
      profissionalSaude: {
        celular: null,
        tratamento: null,
        conselho: null,
        conselhoUf: null,
        conselhoRegistro: null,
        rqe: null,
        profissao: null,
        cbo: null,
      },
      profissionais: [],
      clinicas: [],
      imagem: {
        imagemId: null,
        url: null,
      },
    },
    mapSchema: model => {
      const isProfissionalSaude =
        model.usuario.tipo === UserTipo.PROFISSIONAL_SAUDE;

      return {
        usuario: {
          tipo: {
            label: 'Tipo do usuário',
            type: 'select',
            items: ConstantsHelper.userTipos,
            disabled: isEdit,
            hidden: props.perfil,
            validations: { required: true },
          },
          nome: {
            label: 'Nome',
            type: 'text',
            validations: { required: true },
          },
          email: {
            label: 'Email',
            type: 'email',
            validations: { required: true },
          },
          sexo: {
            label: 'Sexo',
            type: 'select',
            items: ConstantsHelper.sexos,
            layout: { width: 180 },
            validations: { required: true },
          },
          adminAccount: {
            label: 'Adminstrador da conta',
            type: 'switch',
            hidden: props.perfil,
            layout: { sm: 8 },
          },
        },
        profissionalSaude: {
          tratamento: {
            label: 'Tratamento',
            type: 'select',
            list: ConstantsHelper.tratamentos,
            layout: { sm: 3, width: 160 },
          },
          celular: {
            label: 'Celular',
            type: 'text',
            mask: 'celular',
            layout: {
              sm: 9,
              input: { maxWidth: 340 },
            },
          },
          profissao: {
            label: 'Profissão',
            type: 'autocomplete',
            list: ConstantsHelper.profissoesMedicina,
            validations: { required: isProfissionalSaude },
          },
          cbo: {
            label: 'Especialidade (CBO-S)',
            type: 'autocomplete',
            items: ConstantsHelper.tiss.cbo,
          },
          conselho: {
            label: 'Conselho',
            type: 'select',
            items: ConstantsHelper.tiss.conselhos,
            validations: { required: isProfissionalSaude },
            layout: { width: 180 },
          },
          conselhoUf: {
            label: 'UF',
            type: 'select',
            items: ConstantsHelper.estados,
            itemLabel: 'sigla',
            itemValue: 'sigla',
            layout: { width: 100 },
          },
          conselhoRegistro: {
            label: 'Número no conselho',
            type: 'text',
            maxLength: 15,
            layout: { width: 260 },
          },
          rqe: {
            label: 'RQE',
            type: 'text',
            layout: {
              input: { maxWidth: 246 },
            },
          },
        },
      };
    },
    ctx,
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await UserService.getById(id);

      if (!editValue) return redirectError(404);

      setInitialProfissionais(
        (editValue.profissionais || []).map(v => ({
          profissionalId: v.id,
        })),
      );

      setInitialClinicas(
        (editValue.clinicas || []).map(v => ({
          clinicaId: v.clinica.id,
          adminClinica: v.adminClinica,
        })),
      );

      setFormModel({
        usuario: {
          nome: editValue.nome,
          email: editValue.email,
          tipo: editValue.tipo,
          adminAccount: editValue.adminAccount,
          sexo: editValue.sexo,
        },
        redefinirSenha: {
          newPassword: null,
          confirmNewPassword: null,
        },
        profissionalSaude: {
          celular: editValue.celular,
          tratamento: editValue.tratamento,
          conselho: editValue.conselho,
          conselhoUf: editValue.conselhoUf,
          conselhoRegistro: editValue.conselhoRegistro,
          rqe: editValue.rqe,
          profissao: editValue.profissao,
          cbo: editValue.cbo,
        },
        profissionais: [...$initialProfissionais.value],
        clinicas: [...$initialClinicas.value],
        imagem: {
          imagemId: editValue.imagem?.id || null,
          url: editValue.imagem?.thumbnailUrl || editValue.imagem?.url || null,
        },
      });
    },
    mapCustomErrors: model => {
      const errors: IFormValidationErrors[] = [];

      if (!props.perfil) {
        if (model.clinicas.length === 0) {
          errors.push({
            label: 'Clínicas',
            error: 'selecione ao menos uma clínica',
          });
        }

        const isRecepcionista = model.usuario.tipo === UserTipo.RECEPCIONISTA;

        if (isRecepcionista && model.profissionais.length === 0) {
          errors.push({
            label: 'Profissionais',
            error: 'selecione ao menos um profissional',
          });
        }
      }

      if (isEdit) {
        return [...errors, ...$redefinirSenhaErrors.value];
      }

      return errors;
    },
  });

  return {
    $form,
    emitSubmit,
    emitDelete,
    isEdit,
    setRedefinirSenhaErrors,
    $schema,
    $initialProfissionais,
    $initialClinicas,
  };
}
