import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { ClinicaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IClinicaFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { ClinicaForm } from '../../components/forms/ClinicaForm';

export default createComponent({
  name: 'ClinicaFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage({
      ctx,
      async submitCallback(model: IClinicaFormModel) {
        if (page.id) {
          return ClinicaService.update(page.id, model);
        } else {
          return ClinicaService.create(model);
        }
      },
    });

    const { $nome, setNome } = useNome({ ctx });

    const $title = computed(() => {
      if ($nome.value) {
        return `Clínica: ${$nome.value}`;
      }

      return isEdit ? 'Editar Clínica' : 'Nova Clínica';
    });

    async function handleDelete(id: string) {
      return ClinicaService.delete(id);
    }

    return () => (
      <MyPage title={$title.value} form>
        <ClinicaForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </MyPage>
    );
  },
});
