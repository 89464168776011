import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { createComponent } from '@/lib/vue';
import { IAssinaturaModel } from '@/typings';
import { AssinaturaForm } from '../components/forms/AssinaturaForm';

export default createComponent({
  name: 'AssinaturaPage',
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IAssinaturaModel>({
      ctx,
      async submitCallback(model) {},
    });

    return () => (
      <MyPage title="Assinatura">
        <AssinaturaForm page={page} onSubmit={handleSubmit} />
      </MyPage>
    );
  },
});
